import styled from "styled-components";
import {TextArea} from "react-inclusive-components";

const KudosTextArea = styled(TextArea)`
  width: 100%;
  min-height: 60px;
  max-height: 200px;
  resize: vertical;
  font-size: 1rem;
  font-weight: 200;
  font-family: inherit;
`;

export default KudosTextArea;
