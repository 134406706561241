import styled from "styled-components";
import {Input} from "react-inclusive-components";

const KudosInput = styled(Input)`
  width: 100%;
  font-size: 1rem;
  font-weight: 200;
  padding-left: 4px;
  font-family: inherit;
`;

export default KudosInput;
